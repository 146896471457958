*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
.carousel-holder{
    margin-top: 65px;
    padding: 0;
}
.carousel{
    margin-top: 0;
    display: flex;
    justify-content: center;
}
.carousel-frame {
    padding: 0px;
    overflow: auto;
    height: auto;
}
.card-no-background {
    background: none;
}

@media only screen and (min-width: 800px) {
    .carousel-caption {
        top: 40%;
        bottom: auto;
    }
    .carousel-caption h1 {
    font-weight: bolder;
    font-size: 50px;
    text-shadow: 2px 2px  #140b2e;
    }

    .carousel-caption h2 {
        font-weight: bolder;
        font-size: 50px;
        text-shadow: 2px 2px  #140b2e;
    }

    .carousel-caption h3 {
        font-size: 30px;
        }
    .red {
        background-color: #c62123;
        border: none;
        color: white;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 20px;
        border-radius: 8px;
        border-color: white;
        border-width: 2px;
        border-style: solid;
        }
}

@media only screen and (max-width: 800px) {
    .carousel-caption {
        top: 0%;
        bottom: auto;
    }
   

    .carousel-caption h1 {
    font-weight: bolder;
    font-size: 20px;
    }

    .carousel-caption h3 {
    font-size: 15px;
    }
    .carousel-caption p {
    font-size: 10px;
    }
    .red {
        background-color: #c62123;
        border: none;
        color: white;
        padding: 5px 10px;
        text-align: center;
        text-decoration: none;
        display: inline-block;
        font-size: 11px;
        border-radius: 8px;
        margin-bottom: 5px;
      }
    .carousel-indicators{
        display: none !important;
    }
}

